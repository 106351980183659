<template>
    <div>
        <textarea
            :id="textareaId"
            :name="textareaName"
            :rows="rows"
            :type="type"
            :placeholder="placeholder"
            :value="textareaValue"
            :class="isError ? 'input--error' : ''"
            @input="(event) => onChangeTextArea(event)"
        ></textarea>
        <label
            v-if="optimalTextLength"
            class="formgroup__bottomLabel"
            :class="textareaCounterColorClass"
        >
            <span class="textareaCounter">
                Оптимальная длина текста {{ textLength }}/{{ optimalTextLength }}
            </span>
            <Tooltip
                v-if="optimalLengthTooltip"
                class="leisureAnswerLabelImg"
                :tooltipIcon="require('@/assets/img/common/infoBlue.svg')"
                :text="optimalLengthTooltip"
            />
            <img v-else src="@/assets/img/common/infoBlue.svg" alt="info" />
        </label>
        <span v-if="isError" class="h5 error">{{ isError ? errorText : "" }}</span>
    </div>
</template>

<script>
import Tooltip from "@/views/components/Tooltip/Tooltip";
export default {
    name: "TextArea",

    components: {
        Tooltip,
    },

    beforeMount() {
        this.textareaValue = this.defaultTextAreaValue
    },

    data() {
        return {
            textLength: 0,
            textareaCounterColorClass: "colorGray",
            textareaValue: ""
        };
    },

    props: {
        rows: String,
        type: String,
        placeholder: String,
        isError: Boolean,
        errorText: String,
        onChange: Function,
        defaultTextAreaValue: String,
        optimalTextLength: Number,
        optimalLengthTooltip: String,
        textareaId: String,
        textareaName: String,
    },

    methods: {
        onChangeTextArea(event) {
            this.textareaValue = event.target.value;

            if (this.onChange) this.onChange(this.textareaValue);
            if (this.optimalTextLength) this.updateTextLengthCounter(this.textareaValue);
        },

        updateTextLengthCounter(value) {
            this.textLength = value.length;

            if (this.textLength === 0) {
                this.textareaCounterColorClass = "colorGray";
            } else if (this.textLength > 0 && this.textLength <= this.optimalTextLength) {
                this.textareaCounterColorClass = "colorGreen";
            } else if (
                this.textLength > this.optimalTextLength &&
                this.textLength < this.optimalTextLength * 5
            ) {
                this.textareaCounterColorClass = "colorYellow";
            } else {
                this.textareaCounterColorClass = "colorPomegranate";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./textArea.scss";
</style>
