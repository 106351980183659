var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"items":_vm.items,"title":_vm.title,"link":("/" + (this.$route.params.slug) + "/dashboard")}}),(_vm.questions.length)?_c('div',{staticClass:"pageContent backgroundZircon dashboardSurveyContentWrapper"},[(_vm.isVisibleForm)?_c('div',{staticClass:"dashboardSurveyContent"},[_c('div',{staticClass:"dashboardSurveyContent__header"},[(_vm.subtitle)?_c('p',{staticClass:"text2"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()]),_c('div',[_vm._l((_vm.questions),function(item,index){return _c('div',{key:item._id,staticClass:"dashboardSurveyContent__item formgroup"},[_c('label',{staticClass:"formgroup__label2"},[_vm._v(" "+_vm._s(item.ask)+" ")]),(item.type === 'checkbox')?_c('div',{staticClass:"radioClearGroup"},_vm._l((item.options),function(subitem,index){return _c('label',{key:subitem._id,staticClass:"radioClear customRadio customRadio--checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.submitData.answers[item._id]),expression:"submitData.answers[item._id]"}],staticClass:"input--checkbox",attrs:{"type":"checkbox","name":item._id},domProps:{"value":subitem.answer,"checked":Array.isArray(_vm.submitData.answers[item._id])?_vm._i(_vm.submitData.answers[item._id],subitem.answer)>-1:(_vm.submitData.answers[item._id])},on:{"change":function($event){var $$a=_vm.submitData.answers[item._id],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=subitem.answer,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.submitData.answers, item._id, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.submitData.answers, item._id, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.submitData.answers, item._id, $$c)}}}}),_c('span',{staticClass:"customRadio__btn"}),_c('span',[_vm._v(_vm._s(subitem.answer))]),(
                                    subitem.allowFreeAnswer &&
                                        _vm.submitData.answers[item._id].indexOf(
                                            subitem.answer
                                        ) >= 0
                                )?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.submitData.freeAnswers[item._id][subitem._id]
                                ),expression:"\n                                    submitData.freeAnswers[item._id][subitem._id]\n                                "}],staticClass:"radioClear__textarea",attrs:{"rows":"3","placeholder":"Пожалуйста дополните Ваш ответ"},domProps:{"value":(
                                    _vm.submitData.freeAnswers[item._id][subitem._id]
                                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.submitData.freeAnswers[item._id], subitem._id, $event.target.value)}}}):_vm._e()])}),0):(item.type === 'radio')?_c('div',{staticClass:"radioClearGroup"},_vm._l((item.options),function(subitem,index){return _c('label',{key:subitem._id,staticClass:"radioClear"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.submitData.answers[item._id]),expression:"submitData.answers[item._id]"}],staticClass:"input--checkbox",attrs:{"type":"radio","name":item._id},domProps:{"value":subitem.answer,"checked":_vm._q(_vm.submitData.answers[item._id],subitem.answer)},on:{"change":function($event){return _vm.$set(_vm.submitData.answers, item._id, subitem.answer)}}}),_c('span',[_vm._v(_vm._s(subitem.answer))]),(
                                    subitem.allowFreeAnswer &&
                                        _vm.submitData.answers[item._id] ===
                                            subitem.answer
                                )?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.submitData.freeAnswers[item._id][subitem._id]
                                ),expression:"\n                                    submitData.freeAnswers[item._id][subitem._id]\n                                "}],staticClass:"radioClear__textarea",attrs:{"rows":"3","placeholder":"Пожалуйста дополните Ваш ответ"},domProps:{"value":(
                                    _vm.submitData.freeAnswers[item._id][subitem._id]
                                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.submitData.freeAnswers[item._id], subitem._id, $event.target.value)}}}):_vm._e()])}),0):_c('div',{staticClass:"suggestTheme__inputWrapper"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.submitData.answers[item._id]),expression:"submitData.answers[item._id]"}],attrs:{"rows":"3","name":item._id,"placeholder":"Введите Ваш ответ"},domProps:{"value":(_vm.submitData.answers[item._id])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.submitData.answers, item._id, $event.target.value)}}})])])}),_c('div',{staticClass:"dashboardSurveyContent__item"},[_c('Button',{attrs:{"title":"Отправить","extraClass":"button","onClick":function () { return _vm.submitSurvey(); }}})],1)],2)]):_vm._e(),(_vm.isVisibleResult)?_c('div',{staticClass:"dashboardSurveyContent"},[_c('div',{staticClass:"surveyCompletedContent"},[_c('img',{attrs:{"alt":"hands","src":require("@/assets/img/pages/reviews/writeReview/hands.png")}}),_c('div',{staticClass:"surveyCompletedContent__title"},[_c('p',{staticClass:"h3"},[_vm._v(" "+_vm._s(_vm.successTitle)+" ")])]),_c('div',{staticClass:"surveyCompletedContent__subtitle"},[_c('p',{staticClass:"text2 colorFiord"},[_vm._v(" "+_vm._s(_vm.successText)+" ")])]),_c('Button',{staticClass:"surveyCompletedContent__button",attrs:{"title":"Продолжить","extraClass":"button","onClick":function () { return _vm.redirectToTest(); }}})],1)]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }