<template>
    <div :class="tooltipType ? 'tooltip tooltip--' + tooltipType : 'tooltip'">
        <div v-if="tooltipButton">
            <Button isDisabled :title="tooltipButtonText" />
        </div>
        <img v-if="tooltipIcon" v-bind:src="tooltipIcon" class="tooltip__icon" alt="tooltip" />
        <span
            class="text2 tooltipBox"
            :class="
                tooltipDirection === 'right'
                    ? 'text2 tooltipBox tooltipBox--right'
                    : 'text2 tooltipBox'
            "
            :style="boxStyles"
            v-html="text"
        ></span>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";

export default {
    name: "Tooltip",
    components: {
        Button,
    },

    beforeMount() {
        if (this.boxDistance) {
            if (this.tooltipDirection === 'right') {
                this.boxStyles = { left: this.boxDistance + "px" };
            } else {
                this.boxStyles = { right: this.boxDistance + "px" };
            }
        }
    },

    props: {
        tooltipButton: Boolean,
        tooltipButtonText: String,
        text: String,
        tooltipIcon: String,
        tooltipDirection: String,
        boxDistance: Number,
        tooltipType: String
    },

    data() {
        return {
            boxStyles: {}
        }
    }
};
</script>

<style lang="scss" scoped>
@import "tooltip.scss";
</style>
