<template>
    <Layout>
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/dashboard`"
        />

        <div
            v-if="questions.length"
            class="pageContent backgroundZircon dashboardSurveyContentWrapper"
        >
            <div class="dashboardSurveyContent" v-if="isVisibleForm">
                <div class="dashboardSurveyContent__header">
                    <p v-if="subtitle" class="text2">
                        {{ subtitle }}
                    </p>
                </div>
                <div>
                    <div
                        v-for="(item, index) in questions"
                        :key="item._id"
                        class="dashboardSurveyContent__item formgroup"
                    >
                        <label class="formgroup__label2">
                            {{ item.ask }}
                        </label>

                        <div v-if="item.type === 'checkbox'" class="radioClearGroup">
                            <label
                                v-for="(subitem, index) in item.options"
                                :key="subitem._id"
                                class="radioClear customRadio customRadio--checkbox"
                            >
                                <input
                                    class="input--checkbox"
                                    type="checkbox"
                                    :name="item._id"
                                    :value="subitem.answer"
                                    v-model="submitData.answers[item._id]"
                                />
                                <span class="customRadio__btn"></span>
                                <span>{{ subitem.answer }}</span>

                                <textarea
                                    v-if="
                                        subitem.allowFreeAnswer &&
                                            submitData.answers[item._id].indexOf(
                                                subitem.answer
                                            ) >= 0
                                    "
                                    rows="3"
                                    placeholder="Пожалуйста дополните Ваш ответ"
                                    class="radioClear__textarea"
                                    v-model="
                                        submitData.freeAnswers[item._id][subitem._id]
                                    "
                                ></textarea>
                            </label>
                        </div>

                        <div v-else-if="item.type === 'radio'" class="radioClearGroup">
                            <label
                                v-for="(subitem, index) in item.options"
                                :key="subitem._id"
                                class="radioClear"
                            >
                                <input
                                    class="input--checkbox"
                                    type="radio"
                                    :name="item._id"
                                    :value="subitem.answer"
                                    v-model="submitData.answers[item._id]"
                                />
                                <span>{{ subitem.answer }}</span>

                                <textarea
                                    v-if="
                                        subitem.allowFreeAnswer &&
                                            submitData.answers[item._id] ===
                                                subitem.answer
                                    "
                                    rows="3"
                                    placeholder="Пожалуйста дополните Ваш ответ"
                                    class="radioClear__textarea"
                                    v-model="
                                        submitData.freeAnswers[item._id][subitem._id]
                                    "
                                ></textarea>
                            </label>
                        </div>

                        <div v-else class="suggestTheme__inputWrapper">
                            <textarea
                                rows="3"
                                :name="item._id"
                                placeholder="Введите Ваш ответ"
                                v-model="submitData.answers[item._id]"
                            ></textarea>
                        </div>
                    </div>

                    <div class="dashboardSurveyContent__item">
                        <Button
                            title="Отправить"
                            extraClass="button"
                            :onClick="() => submitSurvey()"
                        />
                    </div>
                </div>
            </div>

            <div class="dashboardSurveyContent" v-if="isVisibleResult">
                <div class="surveyCompletedContent">
                    <img
                        alt="hands"
                        src="@/assets/img/pages/reviews/writeReview/hands.png"
                    />
                    <div class="surveyCompletedContent__title">
                        <p class="h3">
                            {{ successTitle }}
                        </p>
                    </div>
                    <div class="surveyCompletedContent__subtitle">
                        <p class="text2 colorFiord">
                            {{ successText }}
                        </p>
                    </div>
                    <Button
                        title="Продолжить"
                        class="surveyCompletedContent__button"
                        extraClass="button"
                        :onClick="() => redirectToTest()"
                    />
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import PageHeader from "@/views/partials/pageHeader/pageHeader";

import TextArea from "@/views/components/TextArea/TextArea";
import Input from "@/views/components/Input/Input";
import Button from "@/views/components/Button/Button";

export default {
    name: "dashboardSurvey",
    components: {
        Layout,
        PageHeader,
        TextArea,
        Input,
        Button,
    },
    props: {},

    computed: {
        member: function() {
            return (
                (this.$store.getters.getMember && this.$store.getters.getMember.data) ||
                {}
            );
        },

        survey__id: function() {
            return (
                (this.member.quizData &&
                    this.member.quizData.quiz &&
                    this.member.quizData.quiz._id) ||
                ""
            );
        },

        title: function() {
            return (
                (this.member.quizData &&
                    this.member.quizData.quiz &&
                    this.member.quizData.quiz.name) ||
                ""
            );
        },

        subtitle: function() {
            return (
                (this.member.quizData &&
                    this.member.quizData.quiz &&
                    this.member.quizData.quiz.subtitle) ||
                ""
            );
        },

        successTitle: function() {
            return (
                (this.member.quizData &&
                    this.member.quizData.quiz &&
                    this.member.quizData.quiz.success &&
                    this.member.quizData.quiz.success.title) ||
                "Анкета перед стартом обучения заполнена"
            );
        },

        successText: function() {
            return (
                (this.member.quizData &&
                    this.member.quizData.quiz &&
                    this.member.quizData.quiz.success &&
                    this.member.quizData.quiz.success.text) ||
                "Благодарим Вас за участие"
            );
        },

        questions: function() {
            return (
                (this.member.quizData &&
                    this.member.quizData.quiz &&
                    this.member.quizData.quiz.questions) ||
                []
            );
        },
    },

    async beforeMount() {
        this.updateMemberStore();

        if (
            !(
                this.member.quizData &&
                this.member.quizData.showQuiz &&
                this.member.quizData.quiz &&
                this.member.quizData.quiz.trigger === "beforeStart"
            )
        ) {
            await this.$router.push("/" + this.$route.params.slug + "/dashboard");
        }

        if (this.questions && this.questions.length) {
            this.$set(this.submitData, "answers", {});
            this.$set(this.submitData, "freeAnswers", {});

            for (let i = 0; i < this.questions.length; i++) {
                if (this.questions[i].type === "checkbox") {
                    this.$set(this.submitData.answers, this.questions[i]._id, []);
                } else {
                    this.$set(this.submitData.answers, this.questions[i]._id, "");
                }

                const optionsFreeAnswers = this.questions[i].options.filter(
                    (item) => item.allowFreeAnswer
                );
                if (
                    this.questions[i].options &&
                    this.questions[i].options.length &&
                    optionsFreeAnswers.length
                ) {
                    this.$set(this.submitData.freeAnswers, this.questions[i]._id, {});

                    for (const [j, optionsFreeAnswer] of optionsFreeAnswers.entries()) {
                        this.$set(
                            this.submitData.freeAnswers[this.questions[i]._id],
                            optionsFreeAnswer._id,
                            ""
                        );
                    }
                }
            }
        }
    },

    watch: {
        "$route.fullPath": function() {
            this.updateMemberStore();
        },
    },

    data() {
        return {
            items: [
                {
                    text: "личный дашборд",
                    to: `/${this.$route.params.slug}/dashboard`,
                },
                {
                    text: "Анкета",
                    active: true,
                },
            ],
            isVisibleForm: true,
            isVisibleResult: false,

            submitData: {},
        };
    },

    methods: {
        async updateMemberStore() {
            const getMemberData = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study"
            );
            if (getMemberData && getMemberData.data && getMemberData.data.result) {
                if (getMemberData.data.code === "programs") {
                    await this.$store.dispatch("clearMember");
                    await this.$router.push("/");
                } else {
                    await this.$store.dispatch("setMember", {
                        data: getMemberData.data,
                    });
                }
            } else {
                console.error(getMemberData.data.error);
                await this.$store.dispatch("clearMember");
                await this.$router.push("/");
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text:
                            getMemberData.data.error === "Пользователь не найден"
                                ? "В момент заморозки личный кабинет недоступен, но после ее окончания Вы с лёгкостью сможете вернуться в кабинет и продолжить обучение"
                                : getMemberData.data.error,
                    },
                });
            }
        },

        async submitSurvey() {
            let emptyFields = false;
            for (let i = 0; i < this.submitData.answers.length; i++) {
                if (!this.submitData.answers[this.questions[i]._id].length) {
                    emptyFields = true;
                }
            }

            if (!emptyFields) {
                const response = await this.axios.post(
                    `/programs/${this.$route.params.slug}/quiz/${this.survey__id}`,
                    this.submitData
                );

                if (response && response.data && response.data.result) {
                    this.isVisibleForm = false;
                    this.isVisibleResult = true;
                } else {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "Ошибка при отправке анкеты.",
                        },
                    });
                }
            } else {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Все поля должны быть заполнены.",
                    },
                });
            }
        },

        async redirectToTest() {
            this.updateMemberStore();
            await this.$router.push(
                "/" + this.$route.params.slug + "/dashboard/opening-test"
            );
        },
    },
};
</script>

<style lang="scss">
@import "./dashboardSurvey.scss";
</style>
